import React, { ChangeEvent, useEffect, useState } from "react";
import "./css/RatingStars.css";

interface RatingStarsProps {
    defaultStars: string;
    onChange: (value: string) => void;
}

export const RatingStars: React.FC<RatingStarsProps> = ({ defaultStars, onChange }) => {
    const starValues = ['5.0', '4.5', '4.0', '3.5', '3.0', '2.5', '2.0', '1.5', '1.0', '0.5'];
    const [selectedStars, setSelectedStars] = useState<string>(defaultStars);

    useEffect(() => {
        setSelectedStars(defaultStars);
    }, [defaultStars]);

    const handleRatingChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        setSelectedStars(value);
        onChange(value);
    };

    return (
        <div className='card_right__rating'>
            <div className='card_right__rating__stars'>
                <fieldset className='rating'>
                    {starValues.map((value, index) => (
                        <React.Fragment key={index}>
                            <input
                                id={`star${index + 1}`}
                                name='rating'
                                type='radio'
                                value={value}
                                checked={value === selectedStars}
                                onChange={handleRatingChange}
                            />
                            <label
                                className={index % 2 === 0 ? 'full' : 'half'}
                                htmlFor={`star${index + 1}`}
                                title={`${value} stars`}
                            ></label>
                        </React.Fragment>
                    ))}
                </fieldset>
            </div>
        </div>
    );
};
