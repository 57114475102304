import { IoArrowBackCircleOutline } from "react-icons/io5";
import { Link } from "react-scroll";
import "../component/css/Banner.css"

export const Banner = () => {
  return (
    <section id='banner'>
      <div className='inner'>
        <header>
          <h1>Tala Retro</h1>
          <p>Connecting you to Congolese Theatre</p>
        </header>

        <Link to='home-content' smooth={true} duration={500} className=''>
          <IoArrowBackCircleOutline style={{ fontSize: '48px', color: 'white', transform: 'rotate(-90deg)' }}/>
        </Link>
      </div>
    </section>
  );
};
