import { Link } from "react-router-dom";
import { Banner } from "./component/Banner";
import { ImageSlider } from "./component/ImageSlider";
import "./css/HomePage.css"

export const HomePage = () => {
  return (
    <div className='mt-5'>
      <div className=''>
        <Banner />
      </div>

      <div style={{height: '250px'}}>
        
      </div>

      <div id='home-content'>
        <br />
      </div>

      <section>
        <div id='movie-section' className='home container' style={{background: 'red'}}>
          <br />
          <h2>New Movies</h2>
          <div className=''>
            {/* <ImageSlider /> */}
          </div>

          <div style={{background: 'black', height: '10px'}}/>

          <h2>Old Movies</h2>
          <div className=''>
            {/* <ImageSlider /> */}
          </div>

          <div style={{background: 'black', height: '10px'}}/>

          <h2>Popular Movies</h2>
          <div className=''>
            {/* <ImageSlider /> */}
          </div>

          <Link to='/movies'>
            <button type="button" className="btn btn-outline-success" > More Movies </button> 
          </Link>
        </div>

        <br/>

        <div id='actor-section' className='home container' style={{background: 'blue'}}>
          <h2>Actors</h2>
          <div className=''>
            {/* <ImageSlider /> */}
          </div>
          <div style={{background: 'black', height: '10px'}}/>
          <h2>Actress</h2>
          <div className=''>
            {/* <ImageSlider /> */}
          </div>
          <Link
            to='/actors' >
            <button  type="button" className="btn btn-outline-success" > More Actors </button> 
          </Link>
        </div>

        <br />

        <div id='channels-section' className='home container' style={{background: 'pink'}}>
          <h2>Channels</h2>
         <div>
            {/* <ImageSlider /> */}
         </div>
        </div>
      </section>
    </div>
  );
};
