import "./css/Pagination.css"

export const Pagination: React.FC<{currentPage: number, totalPages: number, paginate: any}> = (props) => {

    const pageNumbers = [];

    if (props.currentPage === 1) {
        pageNumbers.push(props.currentPage);
        if (props.totalPages >= props.currentPage + 1) {
            pageNumbers.push(props.currentPage + 1);
        }
        if (props.totalPages >= props.currentPage + 2) {
            pageNumbers.push(props.currentPage + 2);
        }
        if (props.totalPages >= props.currentPage + 3) {
            pageNumbers.push(props.currentPage + 3);
        }
        // if (props.totalPages >= props.currentPage + 4) {
        //     pageNumbers.push(props.currentPage + 4);
        // }
    } else if (props.currentPage > 1) {
        if (props.currentPage >= 3) {
            pageNumbers.push(props.currentPage - 2);
            pageNumbers.push(props.currentPage - 1);
        } else {
            pageNumbers.push(props.currentPage - 1);
        }

        pageNumbers.push(props.currentPage);

        if (props.totalPages >= props.currentPage + 1) {
            pageNumbers.push(props.currentPage + 1);
        }

        if (props.totalPages >= props.currentPage + 2) {
            pageNumbers.push(props.currentPage + 2);
        }

        if (props.totalPages >= props.currentPage + 3) {
            pageNumbers.push(props.currentPage + 3);
        }
        // if (props.totalPages >= props.currentPage + 4) {
        //     pageNumbers.push(props.currentPage + 4);
        // }
    }

    return (
        <nav aria-label="...." >
            <ul className="pagination">
                {props.currentPage > 1 && (
                <li className={`page-item`} onClick={() => props.paginate(props.currentPage - 1)}>
                    <button className="page-link" disabled={props.currentPage === 1} >
                        Previous
                    </button>
                </li>
                )}
                {pageNumbers.map(number => (
                    <li key={number} onClick={() => props.paginate(number)} className={`page-item ` + (props.currentPage === number ? `active`: ``)}>
                        <button className="page-link">
                            {number}
                        </button>
                    </li>
                ))}
                {props.currentPage < props.totalPages && (
                    <li className={`page-item`} onClick={() => props.paginate(props.currentPage + 1)}>
                        <button className="page-link">
                            Next
                        </button>
                    </li>
                )}
            </ul>
        </nav>
    );
}