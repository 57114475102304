import "./css/MoviePage.css";
import banner from "../../images/movie-banner.jpeg";

import { useEffect, useState } from "react";
import MovieModel from "../../models/MovieModel";
import MovieEpisodeModel from "../../models/MovieEpisodeModel";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { Modal } from "./components/Modal";
import YouTube from "react-youtube";
import { MovieProfileCard } from "./components/MovieProfileCard";
import { MovieProfileCardMobile } from "./components/MovieProfileCardMobile";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";

export const MoviePage = () => {
  const moviePoster = window.location.pathname.split("/")[2];
  const [movie, setMovie] = useState<MovieModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [bannerHeight, setBannerHeight] = useState<number>(400);
  const [videoId, setVideoId] = useState<string>(
    localStorage.getItem("activeWatchVideo") || `${moviePoster}`
  );

  const [activeIndex, setActiveIndex] = useState(
    parseInt(localStorage.getItem("activeIndex") || "") || 0
  );

  useEffect(() => {
    const fetchMovie = async () => {
      // const url: string = `http://192.168.1.79:8080/api/movies/search/movieposter?value=${moviePoster}`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/movies/search/movieposter?value=${moviePoster}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const loadedEpisodes: MovieEpisodeModel[] = [];
      for (const index in responseJson.episodes) {
        const episode = new MovieEpisodeModel(
          responseJson.episodes[index].episode_id,
          responseJson.episodes[index].episodeNumber,
          responseJson.episodes[index].title.toUpperCase(),
          responseJson.episodes[index].videoId,
          responseJson.episodes[index].description
        );

        loadedEpisodes.push(episode);
        loadedEpisodes.sort((a, b) => a.episode_number - b.episode_number);
      }

      const loadedMovie: MovieModel = new MovieModel(
        responseJson.movie_id,
        responseJson.title.toUpperCase(),
        responseJson.uploadDate,
        responseJson.releaseYear,
        responseJson.genre,
        responseJson.moviePoster,
        loadedEpisodes,
        responseJson.uploadChannel,
        responseJson.dateModified,
      );

      setMovie(loadedMovie);
      setIsLoading(false);
    };
    fetchMovie().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    window.scroll(0, 0);
  }, [moviePoster]);

  useEffect(() => {
    return () => {
      localStorage.removeItem("activeIndex");
      localStorage.removeItem("activeWatchVideo");
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setBannerHeight(500);
      } else {
        setBannerHeight(400);
      }
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleEpisodeButtonClick = (index: number, videoId: string) => {
    setActiveIndex(index);
    localStorage.setItem("activeIndex", index.toString());
    setVideoId(videoId);
    localStorage.setItem("activeWatchVideo", videoId);
  };

  const generateEpisodeGroups = (
    episodesGroupLength: number
  ): React.ReactNode[] => {
    return (movie?.episodes || []).reduce<React.ReactNode[]>(
      (subGroup, episode, index) => {
        if (index % episodesGroupLength === 0) {
          subGroup.push(
            <ul key={index} className='list-group list-group-horizontal gap-3'>
              {[
                ...Array(
                  Math.min(
                    episodesGroupLength,
                    (movie?.episodes?.length ?? 0) - index
                  )
                ),
              ].map((_, i) => {
                const episodeIndex = index + i;
                return (
                  <li key={i}>
                    <button
                      className={`mt-2 list-group-item list-group-item-action rounded${
                        episodeIndex === activeIndex ? " active" : ""
                      }`}
                      type='button'
                      onClick={() =>
                        handleEpisodeButtonClick(
                          episodeIndex,
                          movie?.episodes[episodeIndex].video_Id ?? ""
                        )
                      }
                      // data-bs-toggle='modal'
                      // data-bs-target='.bd-modal-lg'
                      >
                      Episode {movie?.episodes[episodeIndex].episode_number}
                    </button>
                    {/* <Modal>
                      <YouTube
                        className='d-flex justify-content-center overflow-hidden'
                        videoId={videoId}
                        opts={{ height: "400", width: "1000" }}
                      />
                    </Modal> */}
                  </li>
                );
              })}
            </ul>
          );
        }
        return subGroup;
      },
      []
    );
  };
  return (
    <div>
      <div className='container p-5 mt-5'>
        {/* Desktop */}
        {isDesktop && (
          <>
            <div className='d-none desktop-mp d-xl-block'>
              {movie && <MovieProfileCard movie={movie} />}

              <YouTube
                        className='d-flex justify-content-center overflow-hidden mt-5'
                        videoId={videoId}
                        opts={{ height: "600", width: "1000" }}
                      />

              <div className='d-flex flex-column mt-5 mx-auto'>
                {generateEpisodeGroups(8)}
              </div>
            </div>

            <div className='d-none desktop-mp d-lg-block d-xl-none'>
              {movie && <MovieProfileCard movie={movie} />}

              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(6)}
              </div>
            </div>

            <div className='d-none desktop-mp d-md-block d-lg-none'>
              {movie && <MovieProfileCardMobile movie={movie} />}
              
              {/* Modal works until here */}
              {/* {movie && <YouTube
                        className='d-flex justify-content-center overflow-hidden'
                        videoId={videoId}
                        opts={{ height: "400", width: "1000" }}
                      />} */}
               <YouTube
                        className='d-flex justify-content-center overflow-hidden'
                        videoId={videoId}
                        opts={{ height: "400", width: "1000" }}
                      />

              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(4)}
              </div>
            </div>

            <div className='d-none desktop-mp d-sm-block d-md-none'>
              {movie && <MovieProfileCardMobile movie={movie} />}

              <div className='d-flex flex-column mt-5 mx-5'>
                {generateEpisodeGroups(3)}
              </div>
            </div>
            <div className='desktop-mp d-sm-none' style={{ background: "white" }}>
              D-SM-NONE
            </div>
          </>
        )}

        {/* Tablet*/}
        {isTablet && (
          <>
            <br />
            <br />
            <br />

            <div
              className='d-none tablet-mp d-lg-block d-xl-none'
              style={{ background: "white" }}>
              D-LG-BLOCK
            </div>
            <div
              className='d-none tablet-mp d-md-block d-lg-none'
              style={{ background: "white" }}>
              D-MD-BLOCK
            </div>
          </>
        )}

        {/* Mobile*/}
        {isMobileOnly && (
          <>
            <br />
            <br />
            <br />
            <div
              className='d-none mobile-mp d-md-block d-lg-none'
              style={{ background: "white" }}>
              D-MD-BLOCK
            </div>
            <div
              className='d-none mobile-mp d-sm-block d-md-none'
              style={{ background: "white" }}>
              D-SM-BLOCK
            </div>
            <div className='mobile-mp d-sm-none' style={{ background: "white" }}>
              D-SM-NONE
            </div>
          </>
        )}

        <section
          id='banner'
          style={{
            position: "relative",
            backgroundImage: `url(${banner})`,
            height: `${bannerHeight}px`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "100%",
            backgroundColor: "black",
          }}></section>
      </div>
    </div>
  );
};
