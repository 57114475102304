import { Link } from "react-router-dom";
import { ActorCard } from "./components/ActorCard";
import { Pagination } from "../Utils/Pagination";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { useEffect, useState } from "react";
import ActorModel from "../../models/ActorModel";

export const ActorsPage = () => {
  const [actors, setActors] = useState<ActorModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [actorsPerPage, setActorsPerPage] = useState(35);
  const [totalPages, setTotalPages] = useState(0);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchActors = async () => {
      // const url: string = `http://192.168.1.79:8080/api/actors?page=${
      //   currentPage - 1
      // }&size=${actorsPerPage}`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/actors?page=${
        currentPage - 1
      }&size=${actorsPerPage}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();
      const responseData = responseJson.content;

      setTotalPages(responseJson.totalPages);

      const loadedActors: ActorModel[] = [];
      for (const index in responseData) {
        const actorData = responseData[index];
        const actor = new ActorModel(
          actorData.actorId,
          actorData.actorName.toUpperCase(),
          actorData.gender
        );
        loadedActors.push(actor);
      }
      setActors(loadedActors);
      setIsLoading(false);
    };
    fetchActors().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    window.scroll(0, 0);
  }, [currentPage, actorsPerPage]);

  useEffect(() => {
    const handleResize = () => {
      const isFourMoviesPerPage =
        window.innerWidth > 577 && window.innerWidth < 1440;
      const isThreeMoviesPerPage = window.innerWidth <= 576;

      let updatedActorssPerPage = 35;

      if (isFourMoviesPerPage) {
        if(isTablet) {
          updatedActorssPerPage = 30;
        } else {
          updatedActorssPerPage = 32;
        }
      } else if (isThreeMoviesPerPage) {
        updatedActorssPerPage = 30;
      }

      setActorsPerPage(updatedActorssPerPage);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Desktop */}
      {isDesktop && (
        <>
          <div className='d-none actor desktop d-xl-block'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='12rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3 '
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none actor desktop d-lg-block d-xl-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='12rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none actor desktop d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='10rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none actor desktop d-sm-block d-md-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='8.5rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='desktop actor d-sm-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-3 mt-4 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='9rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Tablet*/}
      {isTablet && (
        <>
          <div className='d-none actor tablet d-lg-block d-xl-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='13rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none actor tablet d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}

      {/* Mobile*/}
      {isMobileOnly && (
        <>
          <div className='d-none actor mobile d-md-block d-lg-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-2'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='9rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='d-none mobile d-sm-block d-md-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='9rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-auto mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>

          <div className='mobile d-sm-none'>
            <br />
            <div className='container-fluid'>
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Actors
              </h1>
              <div className='row justify-content-start'>
                {actors.map((actor, index) => (
                  <div key={index} className='col-2 mt-4 mx-5'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/actor/${actor.actor_id}/${actor.actor_Name}`,
                        state: { actor: actor },
                      }}
                      onClick={handleClick}>
                      <ActorCard actor={actor} size='9rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
              <div
                className='mx-3 mt-3'
                onClick={handleClick}
                style={{ width: "200px" }}>
                <Pagination
                  currentPage={currentPage}
                  totalPages={totalPages}
                  paginate={paginate}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
