import { useEffect, useState } from "react";
import { MovieCard } from "./components/MovieCard";
import MovieModel from "../../models/MovieModel";
import MovieEpisodeModel from "../../models/MovieEpisodeModel";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { Pagination } from "../Utils/Pagination";
import { Link } from "react-router-dom";
import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";

export const MoviesPage = () => {
  const [movies, setMovies] = useState<MovieModel[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [moviesPerPage, setMoviesPerPage] = useState(35);
  const [totalPages, setTotalPages] = useState(0);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  useEffect(() => {
    const fetchMovies = async () => {
      // const url: string = `http://192.168.1.79:8080/api/movies?page=${
      //   currentPage - 1
      // }&size=${moviesPerPage}`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/movies?page=${
        currentPage - 1
      }&size=${moviesPerPage}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();
      const responseData = responseJson.content;

      setTotalPages(responseJson.totalPages);

      const loadedMovies: MovieModel[] = [];

      for (const index in responseData) {
        const loadedEpisodes: MovieEpisodeModel[] = [];
        for (const ep_index in responseData[index].episodes) {
          const episodeData = responseData[index].episodes[ep_index];
          const episode = new MovieEpisodeModel(
            episodeData.episode_id,
            episodeData.episodeNumber,
            episodeData.title.toUpperCase(),
            episodeData.videoId,
            episodeData.description
          );
          loadedEpisodes.push(episode);
          loadedEpisodes.sort((a, b) => a.episode_number - b.episode_number);
        }

        const movieData = responseData[index];
        const movie = new MovieModel(
          movieData.movie_id,
          movieData.title.toUpperCase(),
          movieData.uploadDate,
          movieData.releaseYear,
          movieData.genre,
          movieData.moviePoster,
          loadedEpisodes,
          movieData.uploadChannel,
          movieData.dateModified
        );
        loadedMovies.push(movie);
      }
      setMovies(loadedMovies);
      setIsLoading(false);
    };
    fetchMovies().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    window.scroll(0, 0);
  }, [currentPage, moviesPerPage]);

  useEffect(() => {
    const handleResize = () => {
      const isFourMoviesPerPage =
        window.innerWidth > 577 && window.innerWidth < 1440;
      const isThreeMoviesPerPage = window.innerWidth <= 576;

      let updatedMoviesPerPage = 35;

      if (isFourMoviesPerPage) {
        if(isTablet) {
          updatedMoviesPerPage = 30;
          // find a way to check for port or land port = 32 land = 30
        } else {
          updatedMoviesPerPage = 32;
        }
      } else if (isThreeMoviesPerPage) {
        updatedMoviesPerPage = 30;
      }

      setMoviesPerPage(updatedMoviesPerPage);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div>
      {/* Desktop */}
      {isDesktop && (
        <>
          <div className='d-none desktop d-xl-block' >
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='15rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='d-none desktop d-lg-block d-xl-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='13rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='d-none desktop d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='10.5rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='d-none desktop d-sm-block d-md-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='8.5rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='desktop d-sm-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='8rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-5 mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>
        </>
      )}

      {/* Tablet*/}
      {isTablet && (
        <>
          <div className='d-none tablet d-lg-block d-xl-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-3'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='14rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='d-none tablet d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>
        </>
      )}

      {/* Mobile*/}
      {isMobileOnly && (
        <>
          <div className='d-none mobile d-md-block d-lg-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='d-none mobile d-sm-block d-md-none'>
            <div className='container-fluid'>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-2 mt-2 mx-4'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='10rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-auto mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>

          <div className='mobile d-sm-none'>
            <div className=''>
              <br />
              <h1 className='display-4 mt-5' style={{ color: "white" }}>
                Movies
              </h1>
              <div className='row justify-content-start'>
                {movies.map((movie, index) => (
                  <div key={index} className='col-5 mt-2 mx-2'>
                    <Link
                      className='text-decoration-none'
                      to={{
                        pathname: `/movie/${movie.movie_poster}`,
                      }}
                      onClick={handleClick}>
                      <MovieCard movie={movie} size='11rem' />
                    </Link>
                  </div>
                ))}
              </div>
            </div>
            {totalPages > 1 && (
                <div className='mx-3 mt-3' style={{ width: "200px" }}>
                  <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    paginate={paginate}
                  />
                </div>
              )}
          </div>
        </>
      )}
    </div>
  );
};
