import { isDesktop, isMobileOnly, isTablet } from "react-device-detect";
import ActorModel from "../../models/ActorModel";
import { useEffect, useState } from "react";
import { SpinnerLoading } from "../Utils/SpinnerLoading";
import { ActorProfileCard } from "./components/ActorProfileCard";
import "./css/ActorPage.css"

export const ActorPage = () => {
  const actorId = window.location.pathname.split("/")[2];
  const [actor, setActor] = useState<ActorModel>();
  const [isLoading, setIsLoading] = useState(true);
  const [httpError, setHttpError] = useState(null);

  useEffect(() => {
    const fetchActor = async () => {
      // const url: string = `http://192.168.1.79:8080/api/actors/${actorId}`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/actors/${actorId}`;

      const response = await fetch(url);

      if (!response.ok) {
        throw new Error("Something went wrong!");
      }

      const responseJson = await response.json();

      const loadedActor: ActorModel = new ActorModel (
        responseJson.actorId,
        responseJson.actorName,
        responseJson.gender
      );

      setActor(loadedActor);
      setIsLoading(false);
    };
    fetchActor().catch((error: any) => {
      setIsLoading(false);
      setHttpError(error.message);
    });
    // window.scroll(0, 0);
  }, [actorId]);

  if (isLoading) {
    return (
      <div className='d-flex justify-content-center align-items-center vh-100'>
        <SpinnerLoading />
      </div>
    );
  }

  if (httpError) {
    return (
      <div className='container m-5'>
        <p>{httpError}</p>
      </div>
    );
  }

  return (
    <div className='container p-5 mt-5'>
      {/* Desktop */}
      {isDesktop && (
        <>

         <div className='apc desktop d-xl-block'>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {actor && <ActorProfileCard actor={actor} />}
                </div>
                <hr className="custom-hr"/>
          </div>

          <div className='d-none apc desktop d-xl-block' style={{ background: "white" }}>
                {/* <div style={{display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {actor && <ActorProfileCard actor={actor} />}
                </div>
                <hr className="custom-hr"/> */}
                
                {/* http://localhost:8080/api/movies/search/actor-id?value=1&page=0&size=10 */}
                <h1>Catalog</h1>
          </div>

          <div
            className='d-none desktop d-lg-block d-xl-none'
            style={{ background: "white" }}>
            D-LG-BLOCK
          </div>
          <div
            className='d-none desktop d-md-block d-lg-none'
            style={{ background: "white" }}>
            D-MD-BLOCK
          </div>
          <div
            className='d-none desktop d-sm-block d-md-none'
            style={{ background: "white" }}>
            D-SM-BLOCK
          </div>
          <div className='desktop d-sm-none' style={{ background: "white" }}>
            D-SM-NONE
          </div>
        </>
      )}

      {/* Tablet*/}
      {isTablet && (
        <>
          <br />
          <br />
          <br />

          <div
            className='d-none tablet d-lg-block d-xl-none'
            style={{ background: "white" }}>
            D-LG-BLOCK
          </div>
          <div
            className='d-none tablet d-md-block d-lg-none'
            style={{ background: "white" }}>
            D-MD-BLOCK
          </div>
        </>
      )}

      {/* Mobile*/}
      {isMobileOnly && (
        <>
          <br />
          <br />
          <br />
          <div
            className='d-none mobile d-md-block d-lg-none'
            style={{ background: "white" }}>
            D-MD-BLOCK
          </div>
          <div
            className='d-none mobile d-sm-block d-md-none'
            style={{ background: "white" }}>
            D-SM-BLOCK
          </div>
          <div className='mobile d-sm-none' style={{ background: "white" }}>
            D-SM-NONE
          </div>
        </>
      )}
    </div>
  );
};
