import ActorModel from "../../../models/ActorModel";
import image from "../../../images/blank-actor-pic.jpeg";
import "../components/css/ActorProfileCard.css";
import { RatingStars } from "../../Utils/RatingStars";
import { useEffect, useState } from "react";

const convertToNearestHalf = (value: number): string => {
  return (Math.round(value * 2) / 2).toFixed(1);
};

export const ActorProfileCard: React.FC<{ actor: ActorModel }> = (props) => {
  const [rating, setRating] = useState<string>("0");
  const [votes, setVotes] = useState<number>(0);

  const fetchRating = async () => {
    try {
      // const url: string = `http://192.168.1.79:8080/api/ratings/actor/${props.actor.actor_id}/ratings/average`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/actor/${props.actor.actor_id}/ratings/average`;
      const response = await fetch(url);

      const data = await response.text();

      const apiRating: number = parseFloat(data);
      const roundedRating = convertToNearestHalf(apiRating);

      setRating(roundedRating);
    } catch (error) {
      console.error("Error fetching rating:", error);
    }
  };

  const fetchVotesCount = async () => {
    try {
      // const url: string = `http://192.168.1.79:8080/api/ratings/actor/${props.actor.actor_id}/ratings/count`;

      const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/actor/${props.actor.actor_id}/ratings/count`;
      const response = await fetch(url);

      const data = await response.text();

      const apiVotes: number = parseInt(data);

      setVotes(apiVotes);

    } catch (error) {
      console.error("Error fetching rating:", error);
    }
  };

  useEffect(() => {
    fetchRating();
    fetchVotesCount();
  }, [props.actor.actor_id]);

  const handleRatingChange = async (newRating: string) => {
    setRating(newRating);

    try {
      const url: string = `${process.env.REACT_APP_API_URI}/api/ratings/actor/add-rating`;
      const payload = {
        actorId: props.actor.actor_id,
        ratingValue: parseFloat(newRating),
      };

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        await fetchRating();
        await fetchVotesCount();
      } else {
        console.error("Failed to update rating:", response.statusText);
      }
    } catch (error) {
      console.error("Error submitting rating:", error);
    }
  };

  return (
    <div className='apc card' style={{ background: "rgb(119, 113, 113)" }}>
      <div className='header-body'></div>
      <img className='card-img-top mt-3' src={image} alt='Actor Picture' />
      <div className='card-body'>
        <h1 className='card-title'>{props.actor.actor_Name}</h1>
        <div className='rating-container'>
          <RatingStars defaultStars={rating} onChange={handleRatingChange} />
        </div>
        <h4>({votes} votes)</h4> 
      </div>
    </div>
  );
};
