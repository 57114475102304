class MovieEpisodeModel {

    constructor(private _episode_id: number, private _episode_number: number, private _title: string, 
        private _video_Id: string, private _description: string) {

    }

    public get episode_id(): number {
        return this._episode_id;
    }

    public set episode_id(value: number) {
        this._episode_id= value;
    }

    public get episode_number(): number {
        return this._episode_number;
    }

    public set episode_number(value: number) {
        this._episode_number = value;
    }

    public get title(): string {
        return this._title;
    }

    public set title(value: string) {
        this._title = value;
    }

    public get video_Id(): string {
        return this._video_Id;
    }

    public set video_Id(value: string) {
        this._video_Id = value;
    }

    public get description(): string {
        return this._description;
    }

    public set description(value: string) {
        this._description = value;
    }
}

export default MovieEpisodeModel;