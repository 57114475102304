import MovieEpisodeModel from "./MovieEpisodeModel";

class MovieModel {
  constructor(
    private _movie_id: number,
    private _title: string,
    private _upload_date: Date,
    private _release_year: number,
    private _genre: string,
    private _movie_poster: string,
    private _episodes: MovieEpisodeModel[],
    private _upload_channel: string,
    private _date_modified: Date
  ) {}

  public get movie_id(): number {
    return this._movie_id;
  }

  public set movie_id(value: number) {
    this._movie_id = value;
  }

  public get title(): string {
    return this._title;
  }

  public set title(value: string) {
    this._title = value;
  }

  public get upload_date(): Date {
    return this._upload_date;
  }

  public set upload_date(value: Date) {
    this._upload_date = value;
  }

  public get release_year(): number {
    return this._release_year;
  }

  public set release_year(value: number) {
    this._release_year = value;
  }

  public get genre(): string {
    return this._genre;
  }

  public set genre(value: string) {
    this._genre = value;
  }

  public get movie_poster(): string {
    return this._movie_poster;
  }

  public set movie_poster(value: string) {
    this._movie_poster = value;
  }

  public get episodes(): MovieEpisodeModel[] {
    return this._episodes;
  }

  public set episodes(value: MovieEpisodeModel[]) {
    this._episodes = value;
  }

  public get upload_channel(): string {
    return this._upload_channel;
  }

  public set upload_channel(value: string) {
    this._upload_channel = value;
  }

  public get date_modified(): Date {
    return this._date_modified;
  }

  public set date_modified(value: Date) {
    this._date_modified = value;
  }
}

export default MovieModel;
