import "./css/MovieProfileCard.css"
import MovieModel from "../../../models/MovieModel";
import { RatingStars } from "../../Utils/RatingStars"

export const MovieProfileCard: React.FC<{movie: MovieModel}> = (props) => {

        return (
        <div className='profile_card'>
                    <div className='card_left'>
                        <img src={`http://img.youtube.com/vi/${props.movie.movie_poster}/mqdefault.jpg`} alt="Movie Poster" />

                    </div>
                    <div className='card_right'>
                        <h1>{props.movie.title}</h1>
                        <div className='card_right__details'>
                        <ul>
                            <li>{props.movie.release_year} ???</li>
                            <li>{props.movie.episodes.length} episodes</li>
                            <li>{props.movie.genre} ???</li>
                        </ul>
                       {/* <RatingStars /> */}
                        <div className='card_right__review'>
                            <p>The lead character, called 'The Bride,' was a member <br/> 
                            of the Deadly Viper Assassination Squad, led by her <br/>
                            lover 'Bill.' Upon realizing she was pregnant with Bill's<br/>
                            child, 'The Bride' decided to escape her life as a killer.<br/> 
                            She fled to Texas, met a young man, who, on the day<br/> 
                            of their wedding rehearsal was gunned down by....</p>
                            {/* <a href='http://www.imdb.com/title/tt0266697/plotsummary?ref_=tt_stry_pl' target='_blank'>Read more</a> */}
                        </div>
                        <div className='card_right__button'>
                            <button  type="button" className="w-25" 
                            // data-bs-toggle="modal" data-bs-target=".bd-modal-lg"
                            >
                                WATCH
                            </button> 
                        </div>
                        
                        </div>
                    </div>
        </div>
    );
}