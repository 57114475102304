import "./css/MovieCard.css"
import MovieModel from "../../../models/MovieModel";

export const MovieCard: React.FC<{movie: MovieModel, size: string}> = (props) => {
    return (
        <div className="card" style={{width: `${props.size}`, borderColor: 'black'}}>
            <img className="card-img-top" src={`http://img.youtube.com/vi/${props.movie.movie_poster}/mqdefault.jpg`} alt="Movie Thumbnail" />
            <div className="card-body">
                <h5 className="card-title">{props.movie.title}</h5>
            </div>
        </div>
    );
}