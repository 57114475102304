import "./css/ActorCard.css"
import ActorModel from "../../../models/ActorModel";
import image from '../../../images/blank-actor-pic.jpeg'

export const ActorCard: React.FC<{actor: ActorModel, size: string}> = (props) => {
    return (
        <div className="actor card" style={{width: `${props.size}`, borderColor: 'black'}}>
            {/* <img className="card-img-top" src={`http://img.youtube.com/vi/${props.movie.movie_poster}/mqdefault.jpg`} alt="Movie Thumbnail" /> */}
            <img className="card-img-top" src={image} alt="Actor Thumbnail" />
            <div className="card-body">
                <h5 className="card-title">{props.actor.actor_Name}</h5>
            </div>
        </div>
    );
}