class ActorModel {

    constructor(private _actor_id: number, private _actor_Name: string, 
        private _gender: string) {

    }

    public get actor_id(): number {
        return this._actor_id;
    }

    public set actor_id(value: number) {
        this._actor_id= value;
    }

    public get actor_Name(): string {
        return this._actor_Name;
    }

    public set actor_Name(value: string) {
        this._actor_Name = value;
    }

    public get gender(): string {
        return this._gender;
    }

    public set gender(value: string) {
        this._gender = value;
    }
}

export default ActorModel;